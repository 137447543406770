export default {
  state: {
    clickId: "",
    selectedGame: {},
  },

  mutations: {
    SET_CLICKID(state, payload) {
      state.clickId = payload;
    },

    SET_SELECTEDGAME(state, payload) {
      state.selectedGame = payload;
    },
  },

  actions: {
    ADD_CLICKID: ({ commit }, payload) => {
      commit("SET_CLICKID", payload);
    },

    ADD_SELECTEDGAME: ({ commit }, payload) => {
      commit("SET_SELECTEDGAME", payload);
    },
  },

  getters: {
    GET_CLICKID: (state) => state.clickId,
    GET_SELECTEDGAME: (state) => state.selectedGame,
  },
};
